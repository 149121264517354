import React from 'react';
import * as icons from '@mui/icons-material';
export const IconComponentsByName: Record<string, JSX.Element> = {
  Abc: <icons.Abc />,
  AcUnit: <icons.AcUnit />,
  AccessAlarm: <icons.AccessAlarm />,
  AccessAlarms: <icons.AccessAlarms />,
  AccessTime: <icons.AccessTime />,
  AccessTimeFilled: <icons.AccessTimeFilled />,
  Accessibility: <icons.Accessibility />,
  AccessibilityNew: <icons.AccessibilityNew />,
  Accessible: <icons.Accessible />,
  AccessibleForward: <icons.AccessibleForward />,
  AccountBalance: <icons.AccountBalance />,
  AccountBalanceWallet: <icons.AccountBalanceWallet />,
  AccountBox: <icons.AccountBox />,
  AccountCircle: <icons.AccountCircle />,
  AccountTree: <icons.AccountTree />,
  AdUnits: <icons.AdUnits />,
  Adb: <icons.Adb />,
  Add: <icons.Add />,
  AddAPhoto: <icons.AddAPhoto />,
  AddAlarm: <icons.AddAlarm />,
  AddAlert: <icons.AddAlert />,
  AddBox: <icons.AddBox />,
  AddBusiness: <icons.AddBusiness />,
  AddCard: <icons.AddCard />,
  AddCircle: <icons.AddCircle />,
  AddCircleOutline: <icons.AddCircleOutline />,
  AddComment: <icons.AddComment />,
  AddIcCall: <icons.AddIcCall />,
  AddLink: <icons.AddLink />,
  AddLocation: <icons.AddLocation />,
  AddLocationAlt: <icons.AddLocationAlt />,
  AddModerator: <icons.AddModerator />,
  AddPhotoAlternate: <icons.AddPhotoAlternate />,
  AddReaction: <icons.AddReaction />,
  AddRoad: <icons.AddRoad />,
  AddShoppingCart: <icons.AddShoppingCart />,
  AddTask: <icons.AddTask />,
  AddToDrive: <icons.AddToDrive />,
  AddToHomeScreen: <icons.AddToHomeScreen />,
  AddToPhotos: <icons.AddToPhotos />,
  AddToQueue: <icons.AddToQueue />,
  Addchart: <icons.Addchart />,
  AdfScanner: <icons.AdfScanner />,
  Adjust: <icons.Adjust />,
  AdminPanelSettings: <icons.AdminPanelSettings />,
  Agriculture: <icons.Agriculture />,
  Air: <icons.Air />,
  AirlineSeatFlat: <icons.AirlineSeatFlat />,
  AirlineSeatFlatAngled: <icons.AirlineSeatFlatAngled />,
  AirlineSeatIndividualSuite: <icons.AirlineSeatIndividualSuite />,
  AirlineSeatLegroomExtra: <icons.AirlineSeatLegroomExtra />,
  AirlineSeatLegroomNormal: <icons.AirlineSeatLegroomNormal />,
  AirlineSeatLegroomReduced: <icons.AirlineSeatLegroomReduced />,
  AirlineSeatReclineExtra: <icons.AirlineSeatReclineExtra />,
  AirlineSeatReclineNormal: <icons.AirlineSeatReclineNormal />,
  AirlineStops: <icons.AirlineStops />,
  Airlines: <icons.Airlines />,
  AirplaneTicket: <icons.AirplaneTicket />,
  AirplanemodeActive: <icons.AirplanemodeActive />,
  AirplanemodeInactive: <icons.AirplanemodeInactive />,
  Airplay: <icons.Airplay />,
  AirportShuttle: <icons.AirportShuttle />,
  Alarm: <icons.Alarm />,
  AlarmAdd: <icons.AlarmAdd />,
  AlarmOff: <icons.AlarmOff />,
  AlarmOn: <icons.AlarmOn />,
  Album: <icons.Album />,
  AlignHorizontalCenter: <icons.AlignHorizontalCenter />,
  AlignHorizontalLeft: <icons.AlignHorizontalLeft />,
  AlignHorizontalRight: <icons.AlignHorizontalRight />,
  AlignVerticalBottom: <icons.AlignVerticalBottom />,
  AlignVerticalCenter: <icons.AlignVerticalCenter />,
  AlignVerticalTop: <icons.AlignVerticalTop />,
  AllInbox: <icons.AllInbox />,
  AllInclusive: <icons.AllInclusive />,
  AllOut: <icons.AllOut />,
  AltRoute: <icons.AltRoute />,
  AlternateEmail: <icons.AlternateEmail />,
  Analytics: <icons.Analytics />,
  Anchor: <icons.Anchor />,
  Android: <icons.Android />,
  Animation: <icons.Animation />,
  Announcement: <icons.Announcement />,
  Aod: <icons.Aod />,
  Apartment: <icons.Apartment />,
  Api: <icons.Api />,
  AppBlocking: <icons.AppBlocking />,
  AppRegistration: <icons.AppRegistration />,
  AppSettingsAlt: <icons.AppSettingsAlt />,
  AppShortcut: <icons.AppShortcut />,
  Apple: <icons.Apple />,
  Approval: <icons.Approval />,
  Apps: <icons.Apps />,
  AppsOutage: <icons.AppsOutage />,
  Architecture: <icons.Architecture />,
  Archive: <icons.Archive />,
  ArrowBack: <icons.ArrowBack />,
  ArrowBackIos: <icons.ArrowBackIos />,
  ArrowBackIosNew: <icons.ArrowBackIosNew />,
  ArrowCircleDown: <icons.ArrowCircleDown />,
  ArrowCircleLeft: <icons.ArrowCircleLeft />,
  ArrowCircleRight: <icons.ArrowCircleRight />,
  ArrowCircleUp: <icons.ArrowCircleUp />,
  ArrowDownward: <icons.ArrowDownward />,
  ArrowDropDown: <icons.ArrowDropDown />,
  ArrowDropDownCircle: <icons.ArrowDropDownCircle />,
  ArrowDropUp: <icons.ArrowDropUp />,
  ArrowForward: <icons.ArrowForward />,
  ArrowForwardIos: <icons.ArrowForwardIos />,
  ArrowLeft: <icons.ArrowLeft />,
  ArrowRight: <icons.ArrowRight />,
  ArrowRightAlt: <icons.ArrowRightAlt />,
  ArrowUpward: <icons.ArrowUpward />,
  ArtTrack: <icons.ArtTrack />,
  Article: <icons.Article />,
  AspectRatio: <icons.AspectRatio />,
  Assessment: <icons.Assessment />,
  Assignment: <icons.Assignment />,
  AssignmentInd: <icons.AssignmentInd />,
  AssignmentLate: <icons.AssignmentLate />,
  AssignmentReturn: <icons.AssignmentReturn />,
  AssignmentReturned: <icons.AssignmentReturned />,
  AssignmentTurnedIn: <icons.AssignmentTurnedIn />,
  Assistant: <icons.Assistant />,
  AssistantDirection: <icons.AssistantDirection />,
  AssistantPhoto: <icons.AssistantPhoto />,
  AssuredWorkload: <icons.AssuredWorkload />,
  Atm: <icons.Atm />,
  AttachEmail: <icons.AttachEmail />,
  AttachFile: <icons.AttachFile />,
  AttachMoney: <icons.AttachMoney />,
  Attachment: <icons.Attachment />,
  Attractions: <icons.Attractions />,
  Attribution: <icons.Attribution />,
  AudioFile: <icons.AudioFile />,
  Audiotrack: <icons.Audiotrack />,
  AutoAwesome: <icons.AutoAwesome />,
  AutoAwesomeMosaic: <icons.AutoAwesomeMosaic />,
  AutoAwesomeMotion: <icons.AutoAwesomeMotion />,
  AutoDelete: <icons.AutoDelete />,
  AutoFixHigh: <icons.AutoFixHigh />,
  AutoFixNormal: <icons.AutoFixNormal />,
  AutoFixOff: <icons.AutoFixOff />,
  AutoGraph: <icons.AutoGraph />,
  AutoStories: <icons.AutoStories />,
  AutofpsSelect: <icons.AutofpsSelect />,
  Autorenew: <icons.Autorenew />,
  AvTimer: <icons.AvTimer />,
  BabyChangingStation: <icons.BabyChangingStation />,
  Backpack: <icons.Backpack />,
  Backspace: <icons.Backspace />,
  Backup: <icons.Backup />,
  BackupTable: <icons.BackupTable />,
  Badge: <icons.Badge />,
  BakeryDining: <icons.BakeryDining />,
  Balance: <icons.Balance />,
  Balcony: <icons.Balcony />,
  Ballot: <icons.Ballot />,
  BarChart: <icons.BarChart />,
  BatchPrediction: <icons.BatchPrediction />,
  Bathroom: <icons.Bathroom />,
  Bathtub: <icons.Bathtub />,
  Battery20: <icons.Battery20 />,
  Battery30: <icons.Battery30 />,
  Battery50: <icons.Battery50 />,
  Battery60: <icons.Battery60 />,
  Battery80: <icons.Battery80 />,
  Battery90: <icons.Battery90 />,
  BatteryAlert: <icons.BatteryAlert />,
  BatteryCharging20: <icons.BatteryCharging20 />,
  BatteryCharging30: <icons.BatteryCharging30 />,
  BatteryCharging50: <icons.BatteryCharging50 />,
  BatteryCharging60: <icons.BatteryCharging60 />,
  BatteryCharging80: <icons.BatteryCharging80 />,
  BatteryCharging90: <icons.BatteryCharging90 />,
  BatteryChargingFull: <icons.BatteryChargingFull />,
  BatteryFull: <icons.BatteryFull />,
  BatterySaver: <icons.BatterySaver />,
  BatteryStd: <icons.BatteryStd />,
  BatteryUnknown: <icons.BatteryUnknown />,
  BeachAccess: <icons.BeachAccess />,
  Bed: <icons.Bed />,
  BedroomBaby: <icons.BedroomBaby />,
  BedroomChild: <icons.BedroomChild />,
  BedroomParent: <icons.BedroomParent />,
  Bedtime: <icons.Bedtime />,
  BedtimeOff: <icons.BedtimeOff />,
  Beenhere: <icons.Beenhere />,
  Bento: <icons.Bento />,
  BikeScooter: <icons.BikeScooter />,
  Biotech: <icons.Biotech />,
  Blender: <icons.Blender />,
  Block: <icons.Block />,
  Bloodtype: <icons.Bloodtype />,
  Bluetooth: <icons.Bluetooth />,
  BluetoothAudio: <icons.BluetoothAudio />,
  BluetoothConnected: <icons.BluetoothConnected />,
  BluetoothDisabled: <icons.BluetoothDisabled />,
  BluetoothDrive: <icons.BluetoothDrive />,
  BluetoothSearching: <icons.BluetoothSearching />,
  BlurCircular: <icons.BlurCircular />,
  BlurLinear: <icons.BlurLinear />,
  BlurOff: <icons.BlurOff />,
  BlurOn: <icons.BlurOn />,
  Bolt: <icons.Bolt />,
  Book: <icons.Book />,
  BookOnline: <icons.BookOnline />,
  Bookmark: <icons.Bookmark />,
  BookmarkAdd: <icons.BookmarkAdd />,
  BookmarkAdded: <icons.BookmarkAdded />,
  BookmarkBorder: <icons.BookmarkBorder />,
  BookmarkRemove: <icons.BookmarkRemove />,
  Bookmarks: <icons.Bookmarks />,
  BorderAll: <icons.BorderAll />,
  BorderBottom: <icons.BorderBottom />,
  BorderClear: <icons.BorderClear />,
  BorderColor: <icons.BorderColor />,
  BorderHorizontal: <icons.BorderHorizontal />,
  BorderInner: <icons.BorderInner />,
  BorderLeft: <icons.BorderLeft />,
  BorderOuter: <icons.BorderOuter />,
  BorderRight: <icons.BorderRight />,
  BorderStyle: <icons.BorderStyle />,
  BorderTop: <icons.BorderTop />,
  BorderVertical: <icons.BorderVertical />,
  Boy: <icons.Boy />,
  BrandingWatermark: <icons.BrandingWatermark />,
  BreakfastDining: <icons.BreakfastDining />,
  Brightness1: <icons.Brightness1 />,
  Brightness2: <icons.Brightness2 />,
  Brightness3: <icons.Brightness3 />,
  Brightness4: <icons.Brightness4 />,
  Brightness5: <icons.Brightness5 />,
  Brightness6: <icons.Brightness6 />,
  Brightness7: <icons.Brightness7 />,
  BrightnessAuto: <icons.BrightnessAuto />,
  BrightnessHigh: <icons.BrightnessHigh />,
  BrightnessLow: <icons.BrightnessLow />,
  BrightnessMedium: <icons.BrightnessMedium />,
  BrokenImage: <icons.BrokenImage />,
  BrowserNotSupported: <icons.BrowserNotSupported />,
  BrowserUpdated: <icons.BrowserUpdated />,
  BrunchDining: <icons.BrunchDining />,
  Brush: <icons.Brush />,
  BubbleChart: <icons.BubbleChart />,
  BugReport: <icons.BugReport />,
  Build: <icons.Build />,
  BuildCircle: <icons.BuildCircle />,
  Bungalow: <icons.Bungalow />,
  BurstMode: <icons.BurstMode />,
  BusAlert: <icons.BusAlert />,
  Business: <icons.Business />,
  BusinessCenter: <icons.BusinessCenter />,
  Cabin: <icons.Cabin />,
  Cable: <icons.Cable />,
  Cached: <icons.Cached />,
  Cake: <icons.Cake />,
  Calculate: <icons.Calculate />,
  CalendarToday: <icons.CalendarToday />,
  CalendarViewDay: <icons.CalendarViewDay />,
  CalendarViewMonth: <icons.CalendarViewMonth />,
  CalendarViewWeek: <icons.CalendarViewWeek />,
  Call: <icons.Call />,
  CallEnd: <icons.CallEnd />,
  CallMade: <icons.CallMade />,
  CallMerge: <icons.CallMerge />,
  CallMissed: <icons.CallMissed />,
  CallMissedOutgoing: <icons.CallMissedOutgoing />,
  CallReceived: <icons.CallReceived />,
  CallSplit: <icons.CallSplit />,
  CallToAction: <icons.CallToAction />,
  Camera: <icons.Camera />,
  CameraAlt: <icons.CameraAlt />,
  CameraEnhance: <icons.CameraEnhance />,
  CameraFront: <icons.CameraFront />,
  CameraIndoor: <icons.CameraIndoor />,
  CameraOutdoor: <icons.CameraOutdoor />,
  CameraRear: <icons.CameraRear />,
  CameraRoll: <icons.CameraRoll />,
  Cameraswitch: <icons.Cameraswitch />,
  Campaign: <icons.Campaign />,
  Cancel: <icons.Cancel />,
  CancelPresentation: <icons.CancelPresentation />,
  CancelScheduleSend: <icons.CancelScheduleSend />,
  CandlestickChart: <icons.CandlestickChart />,
  CarRental: <icons.CarRental />,
  CarRepair: <icons.CarRepair />,
  CardGiftcard: <icons.CardGiftcard />,
  CardMembership: <icons.CardMembership />,
  CardTravel: <icons.CardTravel />,
  Carpenter: <icons.Carpenter />,
  Cases: <icons.Cases />,
  Casino: <icons.Casino />,
  Cast: <icons.Cast />,
  CastConnected: <icons.CastConnected />,
  CastForEducation: <icons.CastForEducation />,
  Castle: <icons.Castle />,
  CatchingPokemon: <icons.CatchingPokemon />,
  Category: <icons.Category />,
  Celebration: <icons.Celebration />,
  CellTower: <icons.CellTower />,
  CellWifi: <icons.CellWifi />,
  CenterFocusStrong: <icons.CenterFocusStrong />,
  CenterFocusWeak: <icons.CenterFocusWeak />,
  Chair: <icons.Chair />,
  ChairAlt: <icons.ChairAlt />,
  Chalet: <icons.Chalet />,
  ChangeCircle: <icons.ChangeCircle />,
  ChangeHistory: <icons.ChangeHistory />,
  ChargingStation: <icons.ChargingStation />,
  Chat: <icons.Chat />,
  ChatBubble: <icons.ChatBubble />,
  ChatBubbleOutline: <icons.ChatBubbleOutline />,
  Check: <icons.Check />,
  CheckBox: <icons.CheckBox />,
  CheckBoxOutlineBlank: <icons.CheckBoxOutlineBlank />,
  CheckCircle: <icons.CheckCircle />,
  CheckCircleOutline: <icons.CheckCircleOutline />,
  Checkroom: <icons.Checkroom />,
  ChevronLeft: <icons.ChevronLeft />,
  ChevronRight: <icons.ChevronRight />,
  ChildCare: <icons.ChildCare />,
  ChildFriendly: <icons.ChildFriendly />,
  ChromeReaderMode: <icons.ChromeReaderMode />,
  Church: <icons.Church />,
  Circle: <icons.Circle />,
  CircleNotifications: <icons.CircleNotifications />,
  Class: <icons.Class />,
  CleanHands: <icons.CleanHands />,
  CleaningServices: <icons.CleaningServices />,
  Clear: <icons.Clear />,
  ClearAll: <icons.ClearAll />,
  Close: <icons.Close />,
  CloseFullscreen: <icons.CloseFullscreen />,
  ClosedCaption: <icons.ClosedCaption />,
  ClosedCaptionDisabled: <icons.ClosedCaptionDisabled />,
  ClosedCaptionOff: <icons.ClosedCaptionOff />,
  Cloud: <icons.Cloud />,
  CloudCircle: <icons.CloudCircle />,
  CloudDone: <icons.CloudDone />,
  CloudDownload: <icons.CloudDownload />,
  CloudOff: <icons.CloudOff />,
  CloudQueue: <icons.CloudQueue />,
  CloudSync: <icons.CloudSync />,
  CloudUpload: <icons.CloudUpload />,
  Co2: <icons.Co2 />,
  CoPresent: <icons.CoPresent />,
  Code: <icons.Code />,
  CodeOff: <icons.CodeOff />,
  Coffee: <icons.Coffee />,
  CoffeeMaker: <icons.CoffeeMaker />,
  Collections: <icons.Collections />,
  CollectionsBookmark: <icons.CollectionsBookmark />,
  ColorLens: <icons.ColorLens />,
  Colorize: <icons.Colorize />,
  Comment: <icons.Comment />,
  CommentBank: <icons.CommentBank />,
  CommentsDisabled: <icons.CommentsDisabled />,
  Commit: <icons.Commit />,
  Commute: <icons.Commute />,
  Compare: <icons.Compare />,
  CompareArrows: <icons.CompareArrows />,
  CompassCalibration: <icons.CompassCalibration />,
  Compress: <icons.Compress />,
  Computer: <icons.Computer />,
  ConfirmationNumber: <icons.ConfirmationNumber />,
  ConnectWithoutContact: <icons.ConnectWithoutContact />,
  ConnectedTv: <icons.ConnectedTv />,
  ConnectingAirports: <icons.ConnectingAirports />,
  Construction: <icons.Construction />,
  ContactMail: <icons.ContactMail />,
  ContactPage: <icons.ContactPage />,
  ContactPhone: <icons.ContactPhone />,
  ContactSupport: <icons.ContactSupport />,
  Contactless: <icons.Contactless />,
  Contacts: <icons.Contacts />,
  ContentCopy: <icons.ContentCopy />,
  ContentCut: <icons.ContentCut />,
  ContentPaste: <icons.ContentPaste />,
  ContentPasteGo: <icons.ContentPasteGo />,
  ContentPasteOff: <icons.ContentPasteOff />,
  ContentPasteSearch: <icons.ContentPasteSearch />,
  Contrast: <icons.Contrast />,
  ControlCamera: <icons.ControlCamera />,
  ControlPoint: <icons.ControlPoint />,
  ControlPointDuplicate: <icons.ControlPointDuplicate />,
  Cookie: <icons.Cookie />,
  CopyAll: <icons.CopyAll />,
  Copyright: <icons.Copyright />,
  Coronavirus: <icons.Coronavirus />,
  CorporateFare: <icons.CorporateFare />,
  Cottage: <icons.Cottage />,
  Countertops: <icons.Countertops />,
  Create: <icons.Create />,
  CreateNewFolder: <icons.CreateNewFolder />,
  CreditCard: <icons.CreditCard />,
  CreditCardOff: <icons.CreditCardOff />,
  CreditScore: <icons.CreditScore />,
  Crib: <icons.Crib />,
  Crop: <icons.Crop />,
  Crop169: <icons.Crop169 />,
  Crop32: <icons.Crop32 />,
  Crop54: <icons.Crop54 />,
  Crop75: <icons.Crop75 />,
  CropDin: <icons.CropDin />,
  CropFree: <icons.CropFree />,
  CropLandscape: <icons.CropLandscape />,
  CropOriginal: <icons.CropOriginal />,
  CropPortrait: <icons.CropPortrait />,
  CropRotate: <icons.CropRotate />,
  CropSquare: <icons.CropSquare />,
  Css: <icons.Css />,
  CurrencyExchange: <icons.CurrencyExchange />,
  CurrencyFranc: <icons.CurrencyFranc />,
  CurrencyLira: <icons.CurrencyLira />,
  CurrencyPound: <icons.CurrencyPound />,
  CurrencyRuble: <icons.CurrencyRuble />,
  CurrencyRupee: <icons.CurrencyRupee />,
  CurrencyYen: <icons.CurrencyYen />,
  CurrencyYuan: <icons.CurrencyYuan />,
  Dangerous: <icons.Dangerous />,
  DarkMode: <icons.DarkMode />,
  Dashboard: <icons.Dashboard />,
  DashboardCustomize: <icons.DashboardCustomize />,
  DataArray: <icons.DataArray />,
  DataObject: <icons.DataObject />,
  DataSaverOff: <icons.DataSaverOff />,
  DataSaverOn: <icons.DataSaverOn />,
  DataThresholding: <icons.DataThresholding />,
  DataUsage: <icons.DataUsage />,
  DateRange: <icons.DateRange />,
  Deblur: <icons.Deblur />,
  Deck: <icons.Deck />,
  Dehaze: <icons.Dehaze />,
  Delete: <icons.Delete />,
  DeleteForever: <icons.DeleteForever />,
  DeleteOutline: <icons.DeleteOutline />,
  DeleteSweep: <icons.DeleteSweep />,
  DeliveryDining: <icons.DeliveryDining />,
  DensityLarge: <icons.DensityLarge />,
  DensityMedium: <icons.DensityMedium />,
  DensitySmall: <icons.DensitySmall />,
  DepartureBoard: <icons.DepartureBoard />,
  Description: <icons.Description />,
  Deselect: <icons.Deselect />,
  DesignServices: <icons.DesignServices />,
  DesktopAccessDisabled: <icons.DesktopAccessDisabled />,
  DesktopMac: <icons.DesktopMac />,
  DesktopWindows: <icons.DesktopWindows />,
  Details: <icons.Details />,
  DeveloperBoard: <icons.DeveloperBoard />,
  DeveloperBoardOff: <icons.DeveloperBoardOff />,
  DeveloperMode: <icons.DeveloperMode />,
  DeviceHub: <icons.DeviceHub />,
  DeviceThermostat: <icons.DeviceThermostat />,
  DeviceUnknown: <icons.DeviceUnknown />,
  Devices: <icons.Devices />,
  DevicesOther: <icons.DevicesOther />,
  DialerSip: <icons.DialerSip />,
  Dialpad: <icons.Dialpad />,
  Diamond: <icons.Diamond />,
  Difference: <icons.Difference />,
  Dining: <icons.Dining />,
  DinnerDining: <icons.DinnerDining />,
  Directions: <icons.Directions />,
  DirectionsBike: <icons.DirectionsBike />,
  DirectionsBoat: <icons.DirectionsBoat />,
  DirectionsBoatFilled: <icons.DirectionsBoatFilled />,
  DirectionsBus: <icons.DirectionsBus />,
  DirectionsBusFilled: <icons.DirectionsBusFilled />,
  DirectionsCar: <icons.DirectionsCar />,
  DirectionsCarFilled: <icons.DirectionsCarFilled />,
  DirectionsOff: <icons.DirectionsOff />,
  DirectionsRailway: <icons.DirectionsRailway />,
  DirectionsRailwayFilled: <icons.DirectionsRailwayFilled />,
  DirectionsRun: <icons.DirectionsRun />,
  DirectionsSubway: <icons.DirectionsSubway />,
  DirectionsSubwayFilled: <icons.DirectionsSubwayFilled />,
  DirectionsTransit: <icons.DirectionsTransit />,
  DirectionsTransitFilled: <icons.DirectionsTransitFilled />,
  DirectionsWalk: <icons.DirectionsWalk />,
  DirtyLens: <icons.DirtyLens />,
  DisabledByDefault: <icons.DisabledByDefault />,
  DiscFull: <icons.DiscFull />,
  DisplaySettings: <icons.DisplaySettings />,
  Dns: <icons.Dns />,
  DoDisturb: <icons.DoDisturb />,
  DoDisturbAlt: <icons.DoDisturbAlt />,
  DoDisturbOff: <icons.DoDisturbOff />,
  DoDisturbOn: <icons.DoDisturbOn />,
  DoNotDisturb: <icons.DoNotDisturb />,
  DoNotDisturbAlt: <icons.DoNotDisturbAlt />,
  DoNotDisturbOff: <icons.DoNotDisturbOff />,
  DoNotDisturbOn: <icons.DoNotDisturbOn />,
  DoNotDisturbOnTotalSilence: <icons.DoNotDisturbOnTotalSilence />,
  DoNotStep: <icons.DoNotStep />,
  DoNotTouch: <icons.DoNotTouch />,
  Dock: <icons.Dock />,
  DocumentScanner: <icons.DocumentScanner />,
  Domain: <icons.Domain />,
  DomainAdd: <icons.DomainAdd />,
  DomainDisabled: <icons.DomainDisabled />,
  DomainVerification: <icons.DomainVerification />,
  Done: <icons.Done />,
  DoneAll: <icons.DoneAll />,
  DoneOutline: <icons.DoneOutline />,
  DonutLarge: <icons.DonutLarge />,
  DonutSmall: <icons.DonutSmall />,
  DoorBack: <icons.DoorBack />,
  DoorFront: <icons.DoorFront />,
  DoorSliding: <icons.DoorSliding />,
  Doorbell: <icons.Doorbell />,
  DoubleArrow: <icons.DoubleArrow />,
  DownhillSkiing: <icons.DownhillSkiing />,
  Download: <icons.Download />,
  DownloadDone: <icons.DownloadDone />,
  DownloadForOffline: <icons.DownloadForOffline />,
  Downloading: <icons.Downloading />,
  Drafts: <icons.Drafts />,
  DragHandle: <icons.DragHandle />,
  DragIndicator: <icons.DragIndicator />,
  DriveEta: <icons.DriveEta />,
  DriveFileMove: <icons.DriveFileMove />,
  DriveFileRenameOutline: <icons.DriveFileRenameOutline />,
  DriveFolderUpload: <icons.DriveFolderUpload />,
  Dry: <icons.Dry />,
  DryCleaning: <icons.DryCleaning />,
  Duo: <icons.Duo />,
  Dvr: <icons.Dvr />,
  DynamicFeed: <icons.DynamicFeed />,
  DynamicForm: <icons.DynamicForm />,
  EMobiledata: <icons.EMobiledata />,
  Earbuds: <icons.Earbuds />,
  EarbudsBattery: <icons.EarbudsBattery />,
  East: <icons.East />,
  EdgesensorHigh: <icons.EdgesensorHigh />,
  EdgesensorLow: <icons.EdgesensorLow />,
  Edit: <icons.Edit />,
  EditAttributes: <icons.EditAttributes />,
  EditLocation: <icons.EditLocation />,
  EditLocationAlt: <icons.EditLocationAlt />,
  EditNotifications: <icons.EditNotifications />,
  EditOff: <icons.EditOff />,
  EditRoad: <icons.EditRoad />,
  Egg: <icons.Egg />,
  EggAlt: <icons.EggAlt />,
  EightK: <icons.EightK />,
  EightKPlus: <icons.EightKPlus />,
  EightMp: <icons.EightMp />,
  EighteenMp: <icons.EighteenMp />,
  EightteenMp: <icons.EightteenMp />,
  Eject: <icons.Eject />,
  Elderly: <icons.Elderly />,
  ElderlyWoman: <icons.ElderlyWoman />,
  ElectricBike: <icons.ElectricBike />,
  ElectricCar: <icons.ElectricCar />,
  ElectricMoped: <icons.ElectricMoped />,
  ElectricRickshaw: <icons.ElectricRickshaw />,
  ElectricScooter: <icons.ElectricScooter />,
  ElectricalServices: <icons.ElectricalServices />,
  Elevator: <icons.Elevator />,
  ElevenMp: <icons.ElevenMp />,
  Email: <icons.Email />,
  EmojiEmotions: <icons.EmojiEmotions />,
  EmojiEvents: <icons.EmojiEvents />,
  EmojiFlags: <icons.EmojiFlags />,
  EmojiFoodBeverage: <icons.EmojiFoodBeverage />,
  EmojiNature: <icons.EmojiNature />,
  EmojiObjects: <icons.EmojiObjects />,
  EmojiPeople: <icons.EmojiPeople />,
  EmojiSymbols: <icons.EmojiSymbols />,
  EmojiTransportation: <icons.EmojiTransportation />,
  Engineering: <icons.Engineering />,
  EnhancedEncryption: <icons.EnhancedEncryption />,
  Equalizer: <icons.Equalizer />,
  Error: <icons.Error />,
  ErrorOutline: <icons.ErrorOutline />,
  Escalator: <icons.Escalator />,
  EscalatorWarning: <icons.EscalatorWarning />,
  Euro: <icons.Euro />,
  EuroSymbol: <icons.EuroSymbol />,
  EvStation: <icons.EvStation />,
  Event: <icons.Event />,
  EventAvailable: <icons.EventAvailable />,
  EventBusy: <icons.EventBusy />,
  EventNote: <icons.EventNote />,
  EventRepeat: <icons.EventRepeat />,
  EventSeat: <icons.EventSeat />,
  ExitToApp: <icons.ExitToApp />,
  Expand: <icons.Expand />,
  ExpandCircleDown: <icons.ExpandCircleDown />,
  ExpandLess: <icons.ExpandLess />,
  ExpandMore: <icons.ExpandMore />,
  Explicit: <icons.Explicit />,
  Explore: <icons.Explore />,
  ExploreOff: <icons.ExploreOff />,
  Exposure: <icons.Exposure />,
  Extension: <icons.Extension />,
  ExtensionOff: <icons.ExtensionOff />,
  Face: <icons.Face />,
  FaceRetouchingNatural: <icons.FaceRetouchingNatural />,
  FaceRetouchingOff: <icons.FaceRetouchingOff />,
  Facebook: <icons.Facebook />,
  FactCheck: <icons.FactCheck />,
  Factory: <icons.Factory />,
  FamilyRestroom: <icons.FamilyRestroom />,
  FastForward: <icons.FastForward />,
  FastRewind: <icons.FastRewind />,
  Fastfood: <icons.Fastfood />,
  Favorite: <icons.Favorite />,
  FavoriteBorder: <icons.FavoriteBorder />,
  Fax: <icons.Fax />,
  FeaturedPlayList: <icons.FeaturedPlayList />,
  FeaturedVideo: <icons.FeaturedVideo />,
  Feed: <icons.Feed />,
  Feedback: <icons.Feedback />,
  Female: <icons.Female />,
  Fence: <icons.Fence />,
  Festival: <icons.Festival />,
  FiberDvr: <icons.FiberDvr />,
  FiberManualRecord: <icons.FiberManualRecord />,
  FiberNew: <icons.FiberNew />,
  FiberPin: <icons.FiberPin />,
  FiberSmartRecord: <icons.FiberSmartRecord />,
  FifteenMp: <icons.FifteenMp />,
  FileCopy: <icons.FileCopy />,
  FileDownload: <icons.FileDownload />,
  FileDownloadDone: <icons.FileDownloadDone />,
  FileDownloadOff: <icons.FileDownloadOff />,
  FileOpen: <icons.FileOpen />,
  FilePresent: <icons.FilePresent />,
  FileUpload: <icons.FileUpload />,
  Filter: <icons.Filter />,
  Filter1: <icons.Filter1 />,
  Filter2: <icons.Filter2 />,
  Filter3: <icons.Filter3 />,
  Filter4: <icons.Filter4 />,
  Filter5: <icons.Filter5 />,
  Filter6: <icons.Filter6 />,
  Filter7: <icons.Filter7 />,
  Filter8: <icons.Filter8 />,
  Filter9: <icons.Filter9 />,
  Filter9Plus: <icons.Filter9Plus />,
  FilterAlt: <icons.FilterAlt />,
  FilterAltOff: <icons.FilterAltOff />,
  FilterBAndW: <icons.FilterBAndW />,
  FilterCenterFocus: <icons.FilterCenterFocus />,
  FilterDrama: <icons.FilterDrama />,
  FilterFrames: <icons.FilterFrames />,
  FilterHdr: <icons.FilterHdr />,
  FilterList: <icons.FilterList />,
  FilterListOff: <icons.FilterListOff />,
  FilterNone: <icons.FilterNone />,
  FilterTiltShift: <icons.FilterTiltShift />,
  FilterVintage: <icons.FilterVintage />,
  FindInPage: <icons.FindInPage />,
  FindReplace: <icons.FindReplace />,
  Fingerprint: <icons.Fingerprint />,
  FireExtinguisher: <icons.FireExtinguisher />,
  Fireplace: <icons.Fireplace />,
  FirstPage: <icons.FirstPage />,
  FitScreen: <icons.FitScreen />,
  Fitbit: <icons.Fitbit />,
  FitnessCenter: <icons.FitnessCenter />,
  FiveG: <icons.FiveG />,
  FiveK: <icons.FiveK />,
  FiveKPlus: <icons.FiveKPlus />,
  FiveMp: <icons.FiveMp />,
  FivteenMp: <icons.FivteenMp />,
  Flag: <icons.Flag />,
  FlagCircle: <icons.FlagCircle />,
  Flaky: <icons.Flaky />,
  Flare: <icons.Flare />,
  FlashAuto: <icons.FlashAuto />,
  FlashOff: <icons.FlashOff />,
  FlashOn: <icons.FlashOn />,
  FlashlightOff: <icons.FlashlightOff />,
  FlashlightOn: <icons.FlashlightOn />,
  Flatware: <icons.Flatware />,
  Flight: <icons.Flight />,
  FlightClass: <icons.FlightClass />,
  FlightLand: <icons.FlightLand />,
  FlightTakeoff: <icons.FlightTakeoff />,
  Flip: <icons.Flip />,
  FlipCameraAndroid: <icons.FlipCameraAndroid />,
  FlipCameraIos: <icons.FlipCameraIos />,
  FlipToBack: <icons.FlipToBack />,
  FlipToFront: <icons.FlipToFront />,
  Fluorescent: <icons.Fluorescent />,
  FlutterDash: <icons.FlutterDash />,
  FmdBad: <icons.FmdBad />,
  FmdGood: <icons.FmdGood />,
  Folder: <icons.Folder />,
  FolderDelete: <icons.FolderDelete />,
  FolderOff: <icons.FolderOff />,
  FolderOpen: <icons.FolderOpen />,
  FolderShared: <icons.FolderShared />,
  FolderSpecial: <icons.FolderSpecial />,
  FolderZip: <icons.FolderZip />,
  FollowTheSigns: <icons.FollowTheSigns />,
  FontDownload: <icons.FontDownload />,
  FontDownloadOff: <icons.FontDownloadOff />,
  FoodBank: <icons.FoodBank />,
  Forest: <icons.Forest />,
  ForkLeft: <icons.ForkLeft />,
  ForkRight: <icons.ForkRight />,
  FormatAlignCenter: <icons.FormatAlignCenter />,
  FormatAlignJustify: <icons.FormatAlignJustify />,
  FormatAlignLeft: <icons.FormatAlignLeft />,
  FormatAlignRight: <icons.FormatAlignRight />,
  FormatBold: <icons.FormatBold />,
  FormatClear: <icons.FormatClear />,
  FormatColorFill: <icons.FormatColorFill />,
  FormatColorReset: <icons.FormatColorReset />,
  FormatColorText: <icons.FormatColorText />,
  FormatIndentDecrease: <icons.FormatIndentDecrease />,
  FormatIndentIncrease: <icons.FormatIndentIncrease />,
  FormatItalic: <icons.FormatItalic />,
  FormatLineSpacing: <icons.FormatLineSpacing />,
  FormatListBulleted: <icons.FormatListBulleted />,
  FormatListNumbered: <icons.FormatListNumbered />,
  FormatListNumberedRtl: <icons.FormatListNumberedRtl />,
  FormatOverline: <icons.FormatOverline />,
  FormatPaint: <icons.FormatPaint />,
  FormatQuote: <icons.FormatQuote />,
  FormatShapes: <icons.FormatShapes />,
  FormatSize: <icons.FormatSize />,
  FormatStrikethrough: <icons.FormatStrikethrough />,
  FormatTextdirectionLToR: <icons.FormatTextdirectionLToR />,
  FormatTextdirectionRToL: <icons.FormatTextdirectionRToL />,
  FormatUnderlined: <icons.FormatUnderlined />,
  Fort: <icons.Fort />,
  Forum: <icons.Forum />,
  Forward: <icons.Forward />,
  Forward10: <icons.Forward10 />,
  Forward30: <icons.Forward30 />,
  Forward5: <icons.Forward5 />,
  ForwardToInbox: <icons.ForwardToInbox />,
  Foundation: <icons.Foundation />,
  FourGMobiledata: <icons.FourGMobiledata />,
  FourGPlusMobiledata: <icons.FourGPlusMobiledata />,
  FourK: <icons.FourK />,
  FourKPlus: <icons.FourKPlus />,
  FourMp: <icons.FourMp />,
  FourteenMp: <icons.FourteenMp />,
  FreeBreakfast: <icons.FreeBreakfast />,
  Fullscreen: <icons.Fullscreen />,
  FullscreenExit: <icons.FullscreenExit />,
  Functions: <icons.Functions />,
  GMobiledata: <icons.GMobiledata />,
  GTranslate: <icons.GTranslate />,
  Gamepad: <icons.Gamepad />,
  Games: <icons.Games />,
  Garage: <icons.Garage />,
  Gavel: <icons.Gavel />,
  Gesture: <icons.Gesture />,
  GetApp: <icons.GetApp />,
  Gif: <icons.Gif />,
  GifBox: <icons.GifBox />,
  Girl: <icons.Girl />,
  GitHub: <icons.GitHub />,
  Gite: <icons.Gite />,
  GolfCourse: <icons.GolfCourse />,
  Google: <icons.Google />,
  GppBad: <icons.GppBad />,
  GppGood: <icons.GppGood />,
  GppMaybe: <icons.GppMaybe />,
  GpsFixed: <icons.GpsFixed />,
  GpsNotFixed: <icons.GpsNotFixed />,
  GpsOff: <icons.GpsOff />,
  Grade: <icons.Grade />,
  Gradient: <icons.Gradient />,
  Grading: <icons.Grading />,
  Grain: <icons.Grain />,
  GraphicEq: <icons.GraphicEq />,
  Grass: <icons.Grass />,
  Grid3x3: <icons.Grid3x3 />,
  Grid4x4: <icons.Grid4x4 />,
  GridGoldenratio: <icons.GridGoldenratio />,
  GridOff: <icons.GridOff />,
  GridOn: <icons.GridOn />,
  GridView: <icons.GridView />,
  Group: <icons.Group />,
  GroupAdd: <icons.GroupAdd />,
  GroupRemove: <icons.GroupRemove />,
  GroupWork: <icons.GroupWork />,
  Groups: <icons.Groups />,
  HMobiledata: <icons.HMobiledata />,
  HPlusMobiledata: <icons.HPlusMobiledata />,
  Hail: <icons.Hail />,
  Handyman: <icons.Handyman />,
  Hardware: <icons.Hardware />,
  Hd: <icons.Hd />,
  HdrAuto: <icons.HdrAuto />,
  HdrAutoSelect: <icons.HdrAutoSelect />,
  HdrEnhancedSelect: <icons.HdrEnhancedSelect />,
  HdrOff: <icons.HdrOff />,
  HdrOffSelect: <icons.HdrOffSelect />,
  HdrOn: <icons.HdrOn />,
  HdrOnSelect: <icons.HdrOnSelect />,
  HdrPlus: <icons.HdrPlus />,
  HdrStrong: <icons.HdrStrong />,
  HdrWeak: <icons.HdrWeak />,
  Headphones: <icons.Headphones />,
  HeadphonesBattery: <icons.HeadphonesBattery />,
  Headset: <icons.Headset />,
  HeadsetMic: <icons.HeadsetMic />,
  HeadsetOff: <icons.HeadsetOff />,
  Healing: <icons.Healing />,
  HealthAndSafety: <icons.HealthAndSafety />,
  Hearing: <icons.Hearing />,
  HearingDisabled: <icons.HearingDisabled />,
  HeartBroken: <icons.HeartBroken />,
  Height: <icons.Height />,
  Help: <icons.Help />,
  HelpCenter: <icons.HelpCenter />,
  HelpOutline: <icons.HelpOutline />,
  Hevc: <icons.Hevc />,
  Hexagon: <icons.Hexagon />,
  HideImage: <icons.HideImage />,
  HideSource: <icons.HideSource />,
  HighQuality: <icons.HighQuality />,
  Highlight: <icons.Highlight />,
  HighlightAlt: <icons.HighlightAlt />,
  HighlightOff: <icons.HighlightOff />,
  Hiking: <icons.Hiking />,
  History: <icons.History />,
  HistoryEdu: <icons.HistoryEdu />,
  HistoryToggleOff: <icons.HistoryToggleOff />,
  Hive: <icons.Hive />,
  Hls: <icons.Hls />,
  HlsOff: <icons.HlsOff />,
  HolidayVillage: <icons.HolidayVillage />,
  Home: <icons.Home />,
  HomeMax: <icons.HomeMax />,
  HomeMini: <icons.HomeMini />,
  HomeRepairService: <icons.HomeRepairService />,
  HomeWork: <icons.HomeWork />,
  HorizontalRule: <icons.HorizontalRule />,
  HorizontalSplit: <icons.HorizontalSplit />,
  HotTub: <icons.HotTub />,
  Hotel: <icons.Hotel />,
  HourglassBottom: <icons.HourglassBottom />,
  HourglassDisabled: <icons.HourglassDisabled />,
  HourglassEmpty: <icons.HourglassEmpty />,
  HourglassFull: <icons.HourglassFull />,
  HourglassTop: <icons.HourglassTop />,
  House: <icons.House />,
  HouseSiding: <icons.HouseSiding />,
  Houseboat: <icons.Houseboat />,
  HowToReg: <icons.HowToReg />,
  HowToVote: <icons.HowToVote />,
  Html: <icons.Html />,
  Http: <icons.Http />,
  Https: <icons.Https />,
  Hub: <icons.Hub />,
  Hvac: <icons.Hvac />,
  IceSkating: <icons.IceSkating />,
  Icecream: <icons.Icecream />,
  Image: <icons.Image />,
  ImageAspectRatio: <icons.ImageAspectRatio />,
  ImageNotSupported: <icons.ImageNotSupported />,
  ImageSearch: <icons.ImageSearch />,
  ImagesearchRoller: <icons.ImagesearchRoller />,
  ImportContacts: <icons.ImportContacts />,
  ImportExport: <icons.ImportExport />,
  ImportantDevices: <icons.ImportantDevices />,
  Inbox: <icons.Inbox />,
  IndeterminateCheckBox: <icons.IndeterminateCheckBox />,
  Info: <icons.Info />,
  Input: <icons.Input />,
  InsertChart: <icons.InsertChart />,
  InsertComment: <icons.InsertComment />,
  InsertDriveFile: <icons.InsertDriveFile />,
  InsertEmoticon: <icons.InsertEmoticon />,
  InsertInvitation: <icons.InsertInvitation />,
  InsertLink: <icons.InsertLink />,
  InsertPageBreak: <icons.InsertPageBreak />,
  InsertPhoto: <icons.InsertPhoto />,
  Insights: <icons.Insights />,
  Instagram: <icons.Instagram />,
  InstallDesktop: <icons.InstallDesktop />,
  InstallMobile: <icons.InstallMobile />,
  IntegrationInstructions: <icons.IntegrationInstructions />,
  Interests: <icons.Interests />,
  InterpreterMode: <icons.InterpreterMode />,
  Inventory: <icons.Inventory />,
  Inventory2: <icons.Inventory2 />,
  InvertColors: <icons.InvertColors />,
  InvertColorsOff: <icons.InvertColorsOff />,
  IosShare: <icons.IosShare />,
  Iron: <icons.Iron />,
  Iso: <icons.Iso />,
  Javascript: <icons.Javascript />,
  JoinFull: <icons.JoinFull />,
  JoinInner: <icons.JoinInner />,
  JoinLeft: <icons.JoinLeft />,
  JoinRight: <icons.JoinRight />,
  Kayaking: <icons.Kayaking />,
  KebabDining: <icons.KebabDining />,
  Key: <icons.Key />,
  KeyOff: <icons.KeyOff />,
  Keyboard: <icons.Keyboard />,
  KeyboardAlt: <icons.KeyboardAlt />,
  KeyboardArrowDown: <icons.KeyboardArrowDown />,
  KeyboardArrowLeft: <icons.KeyboardArrowLeft />,
  KeyboardArrowRight: <icons.KeyboardArrowRight />,
  KeyboardArrowUp: <icons.KeyboardArrowUp />,
  KeyboardBackspace: <icons.KeyboardBackspace />,
  KeyboardCapslock: <icons.KeyboardCapslock />,
  KeyboardCommandKey: <icons.KeyboardCommandKey />,
  KeyboardControlKey: <icons.KeyboardControlKey />,
  KeyboardDoubleArrowDown: <icons.KeyboardDoubleArrowDown />,
  KeyboardDoubleArrowLeft: <icons.KeyboardDoubleArrowLeft />,
  KeyboardDoubleArrowRight: <icons.KeyboardDoubleArrowRight />,
  KeyboardDoubleArrowUp: <icons.KeyboardDoubleArrowUp />,
  KeyboardHide: <icons.KeyboardHide />,
  KeyboardOptionKey: <icons.KeyboardOptionKey />,
  KeyboardReturn: <icons.KeyboardReturn />,
  KeyboardTab: <icons.KeyboardTab />,
  KeyboardVoice: <icons.KeyboardVoice />,
  KingBed: <icons.KingBed />,
  Kitchen: <icons.Kitchen />,
  Kitesurfing: <icons.Kitesurfing />,
  Label: <icons.Label />,
  LabelImportant: <icons.LabelImportant />,
  LabelOff: <icons.LabelOff />,
  Lan: <icons.Lan />,
  Landscape: <icons.Landscape />,
  Language: <icons.Language />,
  Laptop: <icons.Laptop />,
  LaptopChromebook: <icons.LaptopChromebook />,
  LaptopMac: <icons.LaptopMac />,
  LaptopWindows: <icons.LaptopWindows />,
  LastPage: <icons.LastPage />,
  Launch: <icons.Launch />,
  Layers: <icons.Layers />,
  LayersClear: <icons.LayersClear />,
  Leaderboard: <icons.Leaderboard />,
  LeakAdd: <icons.LeakAdd />,
  LeakRemove: <icons.LeakRemove />,
  LegendToggle: <icons.LegendToggle />,
  Lens: <icons.Lens />,
  LensBlur: <icons.LensBlur />,
  LibraryAdd: <icons.LibraryAdd />,
  LibraryAddCheck: <icons.LibraryAddCheck />,
  LibraryBooks: <icons.LibraryBooks />,
  LibraryMusic: <icons.LibraryMusic />,
  Light: <icons.Light />,
  LightMode: <icons.LightMode />,
  Lightbulb: <icons.Lightbulb />,
  LineAxis: <icons.LineAxis />,
  LineStyle: <icons.LineStyle />,
  LineWeight: <icons.LineWeight />,
  LinearScale: <icons.LinearScale />,
  Link: <icons.Link />,
  LinkOff: <icons.LinkOff />,
  LinkedCamera: <icons.LinkedCamera />,
  LinkedIn: <icons.LinkedIn />,
  Liquor: <icons.Liquor />,
  List: <icons.List />,
  ListAlt: <icons.ListAlt />,
  LiveHelp: <icons.LiveHelp />,
  LiveTv: <icons.LiveTv />,
  Living: <icons.Living />,
  LocalActivity: <icons.LocalActivity />,
  LocalAirport: <icons.LocalAirport />,
  LocalAtm: <icons.LocalAtm />,
  LocalBar: <icons.LocalBar />,
  LocalCafe: <icons.LocalCafe />,
  LocalCarWash: <icons.LocalCarWash />,
  LocalConvenienceStore: <icons.LocalConvenienceStore />,
  LocalDining: <icons.LocalDining />,
  LocalDrink: <icons.LocalDrink />,
  LocalFireDepartment: <icons.LocalFireDepartment />,
  LocalFlorist: <icons.LocalFlorist />,
  LocalGasStation: <icons.LocalGasStation />,
  LocalGroceryStore: <icons.LocalGroceryStore />,
  LocalHospital: <icons.LocalHospital />,
  LocalHotel: <icons.LocalHotel />,
  LocalLaundryService: <icons.LocalLaundryService />,
  LocalLibrary: <icons.LocalLibrary />,
  LocalMall: <icons.LocalMall />,
  LocalMovies: <icons.LocalMovies />,
  LocalOffer: <icons.LocalOffer />,
  LocalParking: <icons.LocalParking />,
  LocalPharmacy: <icons.LocalPharmacy />,
  LocalPhone: <icons.LocalPhone />,
  LocalPizza: <icons.LocalPizza />,
  LocalPlay: <icons.LocalPlay />,
  LocalPolice: <icons.LocalPolice />,
  LocalPostOffice: <icons.LocalPostOffice />,
  LocalPrintshop: <icons.LocalPrintshop />,
  LocalSee: <icons.LocalSee />,
  LocalShipping: <icons.LocalShipping />,
  LocalTaxi: <icons.LocalTaxi />,
  LocationCity: <icons.LocationCity />,
  LocationDisabled: <icons.LocationDisabled />,
  LocationOff: <icons.LocationOff />,
  LocationOn: <icons.LocationOn />,
  LocationSearching: <icons.LocationSearching />,
  Lock: <icons.Lock />,
  LockClock: <icons.LockClock />,
  LockOpen: <icons.LockOpen />,
  LockReset: <icons.LockReset />,
  Login: <icons.Login />,
  LogoDev: <icons.LogoDev />,
  Logout: <icons.Logout />,
  Looks: <icons.Looks />,
  Looks3: <icons.Looks3 />,
  Looks4: <icons.Looks4 />,
  Looks5: <icons.Looks5 />,
  Looks6: <icons.Looks6 />,
  LooksOne: <icons.LooksOne />,
  LooksTwo: <icons.LooksTwo />,
  Loop: <icons.Loop />,
  Loupe: <icons.Loupe />,
  LowPriority: <icons.LowPriority />,
  Loyalty: <icons.Loyalty />,
  LteMobiledata: <icons.LteMobiledata />,
  LtePlusMobiledata: <icons.LtePlusMobiledata />,
  Luggage: <icons.Luggage />,
  LunchDining: <icons.LunchDining />,
  Mail: <icons.Mail />,
  MailOutline: <icons.MailOutline />,
  Male: <icons.Male />,
  Man: <icons.Man />,
  ManageAccounts: <icons.ManageAccounts />,
  ManageSearch: <icons.ManageSearch />,
  Map: <icons.Map />,
  MapsHomeWork: <icons.MapsHomeWork />,
  MapsUgc: <icons.MapsUgc />,
  Margin: <icons.Margin />,
  MarkAsUnread: <icons.MarkAsUnread />,
  MarkChatRead: <icons.MarkChatRead />,
  MarkChatUnread: <icons.MarkChatUnread />,
  MarkEmailRead: <icons.MarkEmailRead />,
  MarkEmailUnread: <icons.MarkEmailUnread />,
  MarkUnreadChatAlt: <icons.MarkUnreadChatAlt />,
  Markunread: <icons.Markunread />,
  MarkunreadMailbox: <icons.MarkunreadMailbox />,
  Masks: <icons.Masks />,
  Maximize: <icons.Maximize />,
  MediaBluetoothOff: <icons.MediaBluetoothOff />,
  MediaBluetoothOn: <icons.MediaBluetoothOn />,
  Mediation: <icons.Mediation />,
  MedicalServices: <icons.MedicalServices />,
  Medication: <icons.Medication />,
  MedicationLiquid: <icons.MedicationLiquid />,
  MeetingRoom: <icons.MeetingRoom />,
  Memory: <icons.Memory />,
  Menu: <icons.Menu />,
  MenuBook: <icons.MenuBook />,
  MenuOpen: <icons.MenuOpen />,
  Merge: <icons.Merge />,
  MergeType: <icons.MergeType />,
  Message: <icons.Message />,
  Mic: <icons.Mic />,
  MicExternalOff: <icons.MicExternalOff />,
  MicExternalOn: <icons.MicExternalOn />,
  MicNone: <icons.MicNone />,
  MicOff: <icons.MicOff />,
  Microwave: <icons.Microwave />,
  MilitaryTech: <icons.MilitaryTech />,
  Minimize: <icons.Minimize />,
  MiscellaneousServices: <icons.MiscellaneousServices />,
  MissedVideoCall: <icons.MissedVideoCall />,
  Mms: <icons.Mms />,
  MobileFriendly: <icons.MobileFriendly />,
  MobileOff: <icons.MobileOff />,
  MobileScreenShare: <icons.MobileScreenShare />,
  MobiledataOff: <icons.MobiledataOff />,
  Mode: <icons.Mode />,
  ModeComment: <icons.ModeComment />,
  ModeEdit: <icons.ModeEdit />,
  ModeEditOutline: <icons.ModeEditOutline />,
  ModeNight: <icons.ModeNight />,
  ModeOfTravel: <icons.ModeOfTravel />,
  ModeStandby: <icons.ModeStandby />,
  ModelTraining: <icons.ModelTraining />,
  MonetizationOn: <icons.MonetizationOn />,
  Money: <icons.Money />,
  MoneyOff: <icons.MoneyOff />,
  MoneyOffCsred: <icons.MoneyOffCsred />,
  Monitor: <icons.Monitor />,
  MonitorHeart: <icons.MonitorHeart />,
  MonitorWeight: <icons.MonitorWeight />,
  MonochromePhotos: <icons.MonochromePhotos />,
  Mood: <icons.Mood />,
  MoodBad: <icons.MoodBad />,
  Moped: <icons.Moped />,
  More: <icons.More />,
  MoreHoriz: <icons.MoreHoriz />,
  MoreTime: <icons.MoreTime />,
  MoreVert: <icons.MoreVert />,
  Mosque: <icons.Mosque />,
  MotionPhotosAuto: <icons.MotionPhotosAuto />,
  MotionPhotosOff: <icons.MotionPhotosOff />,
  Mouse: <icons.Mouse />,
  MoveDown: <icons.MoveDown />,
  MoveToInbox: <icons.MoveToInbox />,
  MoveUp: <icons.MoveUp />,
  Movie: <icons.Movie />,
  MovieCreation: <icons.MovieCreation />,
  MovieFilter: <icons.MovieFilter />,
  Moving: <icons.Moving />,
  Mp: <icons.Mp />,
  MultilineChart: <icons.MultilineChart />,
  MultipleStop: <icons.MultipleStop />,
  Museum: <icons.Museum />,
  MusicNote: <icons.MusicNote />,
  MusicOff: <icons.MusicOff />,
  MusicVideo: <icons.MusicVideo />,
  MyLocation: <icons.MyLocation />,
  Nat: <icons.Nat />,
  Nature: <icons.Nature />,
  NaturePeople: <icons.NaturePeople />,
  NavigateBefore: <icons.NavigateBefore />,
  NavigateNext: <icons.NavigateNext />,
  Navigation: <icons.Navigation />,
  NearMe: <icons.NearMe />,
  NearMeDisabled: <icons.NearMeDisabled />,
  NearbyError: <icons.NearbyError />,
  NearbyOff: <icons.NearbyOff />,
  NetworkCell: <icons.NetworkCell />,
  NetworkCheck: <icons.NetworkCheck />,
  NetworkLocked: <icons.NetworkLocked />,
  NetworkWifi: <icons.NetworkWifi />,
  NewReleases: <icons.NewReleases />,
  Newspaper: <icons.Newspaper />,
  NextPlan: <icons.NextPlan />,
  NextWeek: <icons.NextWeek />,
  Nfc: <icons.Nfc />,
  NightShelter: <icons.NightShelter />,
  Nightlife: <icons.Nightlife />,
  Nightlight: <icons.Nightlight />,
  NightlightRound: <icons.NightlightRound />,
  NightsStay: <icons.NightsStay />,
  NineK: <icons.NineK />,
  NineKPlus: <icons.NineKPlus />,
  NineMp: <icons.NineMp />,
  NineteenMp: <icons.NineteenMp />,
  NoAccounts: <icons.NoAccounts />,
  NoBackpack: <icons.NoBackpack />,
  NoCell: <icons.NoCell />,
  NoDrinks: <icons.NoDrinks />,
  NoEncryption: <icons.NoEncryption />,
  NoEncryptionGmailerrorred: <icons.NoEncryptionGmailerrorred />,
  NoFlash: <icons.NoFlash />,
  NoFood: <icons.NoFood />,
  NoLuggage: <icons.NoLuggage />,
  NoMeals: <icons.NoMeals />,
  NoMeetingRoom: <icons.NoMeetingRoom />,
  NoPhotography: <icons.NoPhotography />,
  NoSim: <icons.NoSim />,
  NoStroller: <icons.NoStroller />,
  NoTransfer: <icons.NoTransfer />,
  NordicWalking: <icons.NordicWalking />,
  North: <icons.North />,
  NorthEast: <icons.NorthEast />,
  NorthWest: <icons.NorthWest />,
  NotAccessible: <icons.NotAccessible />,
  NotInterested: <icons.NotInterested />,
  NotListedLocation: <icons.NotListedLocation />,
  NotStarted: <icons.NotStarted />,
  Note: <icons.Note />,
  NoteAdd: <icons.NoteAdd />,
  NoteAlt: <icons.NoteAlt />,
  Notes: <icons.Notes />,
  NotificationAdd: <icons.NotificationAdd />,
  NotificationImportant: <icons.NotificationImportant />,
  Notifications: <icons.Notifications />,
  NotificationsActive: <icons.NotificationsActive />,
  NotificationsNone: <icons.NotificationsNone />,
  NotificationsOff: <icons.NotificationsOff />,
  NotificationsPaused: <icons.NotificationsPaused />,
  Numbers: <icons.Numbers />,
  OfflineBolt: <icons.OfflineBolt />,
  OfflinePin: <icons.OfflinePin />,
  OfflineShare: <icons.OfflineShare />,
  OndemandVideo: <icons.OndemandVideo />,
  OneK: <icons.OneK />,
  OneKPlus: <icons.OneKPlus />,
  OneKk: <icons.OneKk />,
  OnlinePrediction: <icons.OnlinePrediction />,
  Opacity: <icons.Opacity />,
  OpenInBrowser: <icons.OpenInBrowser />,
  OpenInFull: <icons.OpenInFull />,
  OpenInNew: <icons.OpenInNew />,
  OpenInNewOff: <icons.OpenInNewOff />,
  OpenWith: <icons.OpenWith />,
  OtherHouses: <icons.OtherHouses />,
  Outbound: <icons.Outbound />,
  Outbox: <icons.Outbox />,
  OutdoorGrill: <icons.OutdoorGrill />,
  Outlet: <icons.Outlet />,
  OutlinedFlag: <icons.OutlinedFlag />,
  Padding: <icons.Padding />,
  Pages: <icons.Pages />,
  Pageview: <icons.Pageview />,
  Paid: <icons.Paid />,
  Palette: <icons.Palette />,
  PanTool: <icons.PanTool />,
  PanToolAlt: <icons.PanToolAlt />,
  Panorama: <icons.Panorama />,
  PanoramaFishEye: <icons.PanoramaFishEye />,
  PanoramaHorizontal: <icons.PanoramaHorizontal />,
  PanoramaHorizontalSelect: <icons.PanoramaHorizontalSelect />,
  PanoramaPhotosphere: <icons.PanoramaPhotosphere />,
  PanoramaPhotosphereSelect: <icons.PanoramaPhotosphereSelect />,
  PanoramaVertical: <icons.PanoramaVertical />,
  PanoramaVerticalSelect: <icons.PanoramaVerticalSelect />,
  PanoramaWideAngle: <icons.PanoramaWideAngle />,
  PanoramaWideAngleSelect: <icons.PanoramaWideAngleSelect />,
  Paragliding: <icons.Paragliding />,
  Park: <icons.Park />,
  PartyMode: <icons.PartyMode />,
  Password: <icons.Password />,
  Pattern: <icons.Pattern />,
  Pause: <icons.Pause />,
  PauseCircle: <icons.PauseCircle />,
  PauseCircleFilled: <icons.PauseCircleFilled />,
  PauseCircleOutline: <icons.PauseCircleOutline />,
  PausePresentation: <icons.PausePresentation />,
  Payment: <icons.Payment />,
  Payments: <icons.Payments />,
  PedalBike: <icons.PedalBike />,
  Pending: <icons.Pending />,
  PendingActions: <icons.PendingActions />,
  Pentagon: <icons.Pentagon />,
  People: <icons.People />,
  PeopleAlt: <icons.PeopleAlt />,
  PeopleOutline: <icons.PeopleOutline />,
  Percent: <icons.Percent />,
  PermCameraMic: <icons.PermCameraMic />,
  PermContactCalendar: <icons.PermContactCalendar />,
  PermDataSetting: <icons.PermDataSetting />,
  PermDeviceInformation: <icons.PermDeviceInformation />,
  PermIdentity: <icons.PermIdentity />,
  PermMedia: <icons.PermMedia />,
  PermPhoneMsg: <icons.PermPhoneMsg />,
  PermScanWifi: <icons.PermScanWifi />,
  Person: <icons.Person />,
  PersonAdd: <icons.PersonAdd />,
  PersonAddAlt: <icons.PersonAddAlt />,
  PersonAddAlt1: <icons.PersonAddAlt1 />,
  PersonAddDisabled: <icons.PersonAddDisabled />,
  PersonOff: <icons.PersonOff />,
  PersonOutline: <icons.PersonOutline />,
  PersonPin: <icons.PersonPin />,
  PersonPinCircle: <icons.PersonPinCircle />,
  PersonRemove: <icons.PersonRemove />,
  PersonRemoveAlt1: <icons.PersonRemoveAlt1 />,
  PersonSearch: <icons.PersonSearch />,
  PersonalVideo: <icons.PersonalVideo />,
  PestControl: <icons.PestControl />,
  PestControlRodent: <icons.PestControlRodent />,
  Pets: <icons.Pets />,
  Phishing: <icons.Phishing />,
  Phone: <icons.Phone />,
  PhoneAndroid: <icons.PhoneAndroid />,
  PhoneBluetoothSpeaker: <icons.PhoneBluetoothSpeaker />,
  PhoneCallback: <icons.PhoneCallback />,
  PhoneDisabled: <icons.PhoneDisabled />,
  PhoneEnabled: <icons.PhoneEnabled />,
  PhoneForwarded: <icons.PhoneForwarded />,
  PhoneInTalk: <icons.PhoneInTalk />,
  PhoneIphone: <icons.PhoneIphone />,
  PhoneLocked: <icons.PhoneLocked />,
  PhoneMissed: <icons.PhoneMissed />,
  PhonePaused: <icons.PhonePaused />,
  Phonelink: <icons.Phonelink />,
  PhonelinkErase: <icons.PhonelinkErase />,
  PhonelinkLock: <icons.PhonelinkLock />,
  PhonelinkOff: <icons.PhonelinkOff />,
  PhonelinkRing: <icons.PhonelinkRing />,
  PhonelinkSetup: <icons.PhonelinkSetup />,
  Photo: <icons.Photo />,
  PhotoAlbum: <icons.PhotoAlbum />,
  PhotoCamera: <icons.PhotoCamera />,
  PhotoCameraBack: <icons.PhotoCameraBack />,
  PhotoCameraFront: <icons.PhotoCameraFront />,
  PhotoFilter: <icons.PhotoFilter />,
  PhotoLibrary: <icons.PhotoLibrary />,
  PhotoSizeSelectActual: <icons.PhotoSizeSelectActual />,
  PhotoSizeSelectLarge: <icons.PhotoSizeSelectLarge />,
  PhotoSizeSelectSmall: <icons.PhotoSizeSelectSmall />,
  Php: <icons.Php />,
  Piano: <icons.Piano />,
  PianoOff: <icons.PianoOff />,
  PictureAsPdf: <icons.PictureAsPdf />,
  PictureInPicture: <icons.PictureInPicture />,
  PictureInPictureAlt: <icons.PictureInPictureAlt />,
  PieChart: <icons.PieChart />,
  PieChartOutline: <icons.PieChartOutline />,
  Pin: <icons.Pin />,
  PinDrop: <icons.PinDrop />,
  Pinch: <icons.Pinch />,
  Pinterest: <icons.Pinterest />,
  PivotTableChart: <icons.PivotTableChart />,
  Pix: <icons.Pix />,
  Place: <icons.Place />,
  Plagiarism: <icons.Plagiarism />,
  PlayArrow: <icons.PlayArrow />,
  PlayCircle: <icons.PlayCircle />,
  PlayCircleFilled: <icons.PlayCircleFilled />,
  PlayCircleFilledWhite: <icons.PlayCircleFilledWhite />,
  PlayCircleOutline: <icons.PlayCircleOutline />,
  PlayDisabled: <icons.PlayDisabled />,
  PlayForWork: <icons.PlayForWork />,
  PlayLesson: <icons.PlayLesson />,
  PlaylistAdd: <icons.PlaylistAdd />,
  PlaylistAddCheck: <icons.PlaylistAddCheck />,
  PlaylistAddCheckCircle: <icons.PlaylistAddCheckCircle />,
  PlaylistAddCircle: <icons.PlaylistAddCircle />,
  PlaylistPlay: <icons.PlaylistPlay />,
  PlaylistRemove: <icons.PlaylistRemove />,
  Plumbing: <icons.Plumbing />,
  PlusOne: <icons.PlusOne />,
  Podcasts: <icons.Podcasts />,
  PointOfSale: <icons.PointOfSale />,
  Policy: <icons.Policy />,
  Poll: <icons.Poll />,
  Polyline: <icons.Polyline />,
  Pool: <icons.Pool />,
  PortableWifiOff: <icons.PortableWifiOff />,
  Portrait: <icons.Portrait />,
  PostAdd: <icons.PostAdd />,
  Power: <icons.Power />,
  PowerInput: <icons.PowerInput />,
  PowerOff: <icons.PowerOff />,
  PowerSettingsNew: <icons.PowerSettingsNew />,
  PrecisionManufacturing: <icons.PrecisionManufacturing />,
  PregnantWoman: <icons.PregnantWoman />,
  PresentToAll: <icons.PresentToAll />,
  Preview: <icons.Preview />,
  PriceChange: <icons.PriceChange />,
  PriceCheck: <icons.PriceCheck />,
  Print: <icons.Print />,
  PrintDisabled: <icons.PrintDisabled />,
  PriorityHigh: <icons.PriorityHigh />,
  PrivacyTip: <icons.PrivacyTip />,
  ProductionQuantityLimits: <icons.ProductionQuantityLimits />,
  Psychology: <icons.Psychology />,
  Public: <icons.Public />,
  PublicOff: <icons.PublicOff />,
  Publish: <icons.Publish />,
  PublishedWithChanges: <icons.PublishedWithChanges />,
  PunchClock: <icons.PunchClock />,
  PushPin: <icons.PushPin />,
  QrCode: <icons.QrCode />,
  QrCode2: <icons.QrCode2 />,
  QrCodeScanner: <icons.QrCodeScanner />,
  QueryBuilder: <icons.QueryBuilder />,
  QueryStats: <icons.QueryStats />,
  QuestionAnswer: <icons.QuestionAnswer />,
  QuestionMark: <icons.QuestionMark />,
  Queue: <icons.Queue />,
  QueueMusic: <icons.QueueMusic />,
  QueuePlayNext: <icons.QueuePlayNext />,
  Quickreply: <icons.Quickreply />,
  Quiz: <icons.Quiz />,
  RMobiledata: <icons.RMobiledata />,
  Radar: <icons.Radar />,
  Radio: <icons.Radio />,
  RadioButtonChecked: <icons.RadioButtonChecked />,
  RadioButtonUnchecked: <icons.RadioButtonUnchecked />,
  RailwayAlert: <icons.RailwayAlert />,
  RamenDining: <icons.RamenDining />,
  RampLeft: <icons.RampLeft />,
  RampRight: <icons.RampRight />,
  RateReview: <icons.RateReview />,
  RawOff: <icons.RawOff />,
  RawOn: <icons.RawOn />,
  ReadMore: <icons.ReadMore />,
  Receipt: <icons.Receipt />,
  ReceiptLong: <icons.ReceiptLong />,
  RecentActors: <icons.RecentActors />,
  Recommend: <icons.Recommend />,
  RecordVoiceOver: <icons.RecordVoiceOver />,
  Rectangle: <icons.Rectangle />,
  Reddit: <icons.Reddit />,
  Redeem: <icons.Redeem />,
  Redo: <icons.Redo />,
  ReduceCapacity: <icons.ReduceCapacity />,
  Refresh: <icons.Refresh />,
  RememberMe: <icons.RememberMe />,
  Remove: <icons.Remove />,
  RemoveCircle: <icons.RemoveCircle />,
  RemoveCircleOutline: <icons.RemoveCircleOutline />,
  RemoveDone: <icons.RemoveDone />,
  RemoveFromQueue: <icons.RemoveFromQueue />,
  RemoveModerator: <icons.RemoveModerator />,
  RemoveRedEye: <icons.RemoveRedEye />,
  RemoveShoppingCart: <icons.RemoveShoppingCart />,
  Reorder: <icons.Reorder />,
  Repeat: <icons.Repeat />,
  RepeatOn: <icons.RepeatOn />,
  RepeatOne: <icons.RepeatOne />,
  RepeatOneOn: <icons.RepeatOneOn />,
  Replay: <icons.Replay />,
  Replay10: <icons.Replay10 />,
  Replay30: <icons.Replay30 />,
  Replay5: <icons.Replay5 />,
  ReplayCircleFilled: <icons.ReplayCircleFilled />,
  Reply: <icons.Reply />,
  ReplyAll: <icons.ReplyAll />,
  Report: <icons.Report />,
  ReportGmailerrorred: <icons.ReportGmailerrorred />,
  ReportOff: <icons.ReportOff />,
  ReportProblem: <icons.ReportProblem />,
  RequestPage: <icons.RequestPage />,
  RequestQuote: <icons.RequestQuote />,
  ResetTv: <icons.ResetTv />,
  RestartAlt: <icons.RestartAlt />,
  Restaurant: <icons.Restaurant />,
  RestaurantMenu: <icons.RestaurantMenu />,
  Restore: <icons.Restore />,
  RestoreFromTrash: <icons.RestoreFromTrash />,
  RestorePage: <icons.RestorePage />,
  Reviews: <icons.Reviews />,
  RiceBowl: <icons.RiceBowl />,
  RingVolume: <icons.RingVolume />,
  Rocket: <icons.Rocket />,
  RocketLaunch: <icons.RocketLaunch />,
  Roofing: <icons.Roofing />,
  Room: <icons.Room />,
  RoomPreferences: <icons.RoomPreferences />,
  RoomService: <icons.RoomService />,
  Rotate90DegreesCcw: <icons.Rotate90DegreesCcw />,
  Rotate90DegreesCw: <icons.Rotate90DegreesCw />,
  RotateLeft: <icons.RotateLeft />,
  RotateRight: <icons.RotateRight />,
  RoundaboutLeft: <icons.RoundaboutLeft />,
  RoundaboutRight: <icons.RoundaboutRight />,
  RoundedCorner: <icons.RoundedCorner />,
  Route: <icons.Route />,
  Router: <icons.Router />,
  Rowing: <icons.Rowing />,
  RssFeed: <icons.RssFeed />,
  Rsvp: <icons.Rsvp />,
  Rtt: <icons.Rtt />,
  Rule: <icons.Rule />,
  RuleFolder: <icons.RuleFolder />,
  RunCircle: <icons.RunCircle />,
  RunningWithErrors: <icons.RunningWithErrors />,
  RvHookup: <icons.RvHookup />,
  SafetyDivider: <icons.SafetyDivider />,
  Sailing: <icons.Sailing />,
  Sanitizer: <icons.Sanitizer />,
  Satellite: <icons.Satellite />,
  SatelliteAlt: <icons.SatelliteAlt />,
  Save: <icons.Save />,
  SaveAlt: <icons.SaveAlt />,
  SaveAs: <icons.SaveAs />,
  SavedSearch: <icons.SavedSearch />,
  Savings: <icons.Savings />,
  Scale: <icons.Scale />,
  Scanner: <icons.Scanner />,
  ScatterPlot: <icons.ScatterPlot />,
  Schedule: <icons.Schedule />,
  ScheduleSend: <icons.ScheduleSend />,
  Schema: <icons.Schema />,
  School: <icons.School />,
  Science: <icons.Science />,
  Score: <icons.Score />,
  ScreenLockLandscape: <icons.ScreenLockLandscape />,
  ScreenLockPortrait: <icons.ScreenLockPortrait />,
  ScreenLockRotation: <icons.ScreenLockRotation />,
  ScreenRotation: <icons.ScreenRotation />,
  ScreenSearchDesktop: <icons.ScreenSearchDesktop />,
  ScreenShare: <icons.ScreenShare />,
  Screenshot: <icons.Screenshot />,
  Sd: <icons.Sd />,
  SdCard: <icons.SdCard />,
  SdCardAlert: <icons.SdCardAlert />,
  SdStorage: <icons.SdStorage />,
  Search: <icons.Search />,
  SearchOff: <icons.SearchOff />,
  Security: <icons.Security />,
  SecurityUpdate: <icons.SecurityUpdate />,
  SecurityUpdateGood: <icons.SecurityUpdateGood />,
  SecurityUpdateWarning: <icons.SecurityUpdateWarning />,
  Segment: <icons.Segment />,
  SelectAll: <icons.SelectAll />,
  SelfImprovement: <icons.SelfImprovement />,
  Sell: <icons.Sell />,
  Send: <icons.Send />,
  SendAndArchive: <icons.SendAndArchive />,
  SendTimeExtension: <icons.SendTimeExtension />,
  SendToMobile: <icons.SendToMobile />,
  SensorDoor: <icons.SensorDoor />,
  SensorWindow: <icons.SensorWindow />,
  Sensors: <icons.Sensors />,
  SensorsOff: <icons.SensorsOff />,
  SentimentDissatisfied: <icons.SentimentDissatisfied />,
  SentimentNeutral: <icons.SentimentNeutral />,
  SentimentSatisfied: <icons.SentimentSatisfied />,
  SentimentSatisfiedAlt: <icons.SentimentSatisfiedAlt />,
  SentimentVeryDissatisfied: <icons.SentimentVeryDissatisfied />,
  SentimentVerySatisfied: <icons.SentimentVerySatisfied />,
  SetMeal: <icons.SetMeal />,
  Settings: <icons.Settings />,
  SettingsAccessibility: <icons.SettingsAccessibility />,
  SettingsApplications: <icons.SettingsApplications />,
  SettingsBackupRestore: <icons.SettingsBackupRestore />,
  SettingsBluetooth: <icons.SettingsBluetooth />,
  SettingsBrightness: <icons.SettingsBrightness />,
  SettingsCell: <icons.SettingsCell />,
  SettingsEthernet: <icons.SettingsEthernet />,
  SettingsInputAntenna: <icons.SettingsInputAntenna />,
  SettingsInputComponent: <icons.SettingsInputComponent />,
  SettingsInputComposite: <icons.SettingsInputComposite />,
  SettingsInputHdmi: <icons.SettingsInputHdmi />,
  SettingsInputSvideo: <icons.SettingsInputSvideo />,
  SettingsOverscan: <icons.SettingsOverscan />,
  SettingsPhone: <icons.SettingsPhone />,
  SettingsPower: <icons.SettingsPower />,
  SettingsRemote: <icons.SettingsRemote />,
  SettingsSuggest: <icons.SettingsSuggest />,
  SettingsSystemDaydream: <icons.SettingsSystemDaydream />,
  SettingsVoice: <icons.SettingsVoice />,
  SevenK: <icons.SevenK />,
  SevenKPlus: <icons.SevenKPlus />,
  SevenMp: <icons.SevenMp />,
  SeventeenMp: <icons.SeventeenMp />,
  Share: <icons.Share />,
  ShareLocation: <icons.ShareLocation />,
  Shield: <icons.Shield />,
  ShieldMoon: <icons.ShieldMoon />,
  Shop: <icons.Shop />,
  Shop2: <icons.Shop2 />,
  ShopTwo: <icons.ShopTwo />,
  ShoppingBag: <icons.ShoppingBag />,
  ShoppingBasket: <icons.ShoppingBasket />,
  ShoppingCart: <icons.ShoppingCart />,
  ShoppingCartCheckout: <icons.ShoppingCartCheckout />,
  ShortText: <icons.ShortText />,
  Shortcut: <icons.Shortcut />,
  ShowChart: <icons.ShowChart />,
  Shower: <icons.Shower />,
  Shuffle: <icons.Shuffle />,
  ShuffleOn: <icons.ShuffleOn />,
  ShutterSpeed: <icons.ShutterSpeed />,
  Sick: <icons.Sick />,
  SignalCellular0Bar: <icons.SignalCellular0Bar />,
  SignalCellular1Bar: <icons.SignalCellular1Bar />,
  SignalCellular2Bar: <icons.SignalCellular2Bar />,
  SignalCellular3Bar: <icons.SignalCellular3Bar />,
  SignalCellular4Bar: <icons.SignalCellular4Bar />,
  SignalCellularAlt: <icons.SignalCellularAlt />,
  SignalCellularConnectedNoInternet0Bar: (
    <icons.SignalCellularConnectedNoInternet0Bar />
  ),
  SignalCellularConnectedNoInternet1Bar: (
    <icons.SignalCellularConnectedNoInternet1Bar />
  ),
  SignalCellularConnectedNoInternet2Bar: (
    <icons.SignalCellularConnectedNoInternet2Bar />
  ),
  SignalCellularConnectedNoInternet3Bar: (
    <icons.SignalCellularConnectedNoInternet3Bar />
  ),
  SignalCellularConnectedNoInternet4Bar: (
    <icons.SignalCellularConnectedNoInternet4Bar />
  ),
  SignalCellularNoSim: <icons.SignalCellularNoSim />,
  SignalCellularNodata: <icons.SignalCellularNodata />,
  SignalCellularNull: <icons.SignalCellularNull />,
  SignalCellularOff: <icons.SignalCellularOff />,
  SignalWifi0Bar: <icons.SignalWifi0Bar />,
  SignalWifi1Bar: <icons.SignalWifi1Bar />,
  SignalWifi1BarLock: <icons.SignalWifi1BarLock />,
  SignalWifi2Bar: <icons.SignalWifi2Bar />,
  SignalWifi2BarLock: <icons.SignalWifi2BarLock />,
  SignalWifi3Bar: <icons.SignalWifi3Bar />,
  SignalWifi3BarLock: <icons.SignalWifi3BarLock />,
  SignalWifi4Bar: <icons.SignalWifi4Bar />,
  SignalWifi4BarLock: <icons.SignalWifi4BarLock />,
  SignalWifiBad: <icons.SignalWifiBad />,
  SignalWifiConnectedNoInternet4: <icons.SignalWifiConnectedNoInternet4 />,
  SignalWifiOff: <icons.SignalWifiOff />,
  SignalWifiStatusbar4Bar: <icons.SignalWifiStatusbar4Bar />,
  SignalWifiStatusbarConnectedNoInternet4: (
    <icons.SignalWifiStatusbarConnectedNoInternet4 />
  ),
  SignalWifiStatusbarNull: <icons.SignalWifiStatusbarNull />,
  Signpost: <icons.Signpost />,
  SimCard: <icons.SimCard />,
  SimCardAlert: <icons.SimCardAlert />,
  SimCardDownload: <icons.SimCardDownload />,
  SingleBed: <icons.SingleBed />,
  Sip: <icons.Sip />,
  SixK: <icons.SixK />,
  SixKPlus: <icons.SixKPlus />,
  SixMp: <icons.SixMp />,
  SixteenMp: <icons.SixteenMp />,
  SixtyFps: <icons.SixtyFps />,
  SixtyFpsSelect: <icons.SixtyFpsSelect />,
  Skateboarding: <icons.Skateboarding />,
  SkipNext: <icons.SkipNext />,
  SkipPrevious: <icons.SkipPrevious />,
  Sledding: <icons.Sledding />,
  Slideshow: <icons.Slideshow />,
  SlowMotionVideo: <icons.SlowMotionVideo />,
  SmartButton: <icons.SmartButton />,
  SmartDisplay: <icons.SmartDisplay />,
  SmartScreen: <icons.SmartScreen />,
  SmartToy: <icons.SmartToy />,
  Smartphone: <icons.Smartphone />,
  SmokeFree: <icons.SmokeFree />,
  SmokingRooms: <icons.SmokingRooms />,
  Sms: <icons.Sms />,
  SmsFailed: <icons.SmsFailed />,
  SnippetFolder: <icons.SnippetFolder />,
  Snooze: <icons.Snooze />,
  Snowboarding: <icons.Snowboarding />,
  Snowmobile: <icons.Snowmobile />,
  Snowshoeing: <icons.Snowshoeing />,
  Soap: <icons.Soap />,
  SocialDistance: <icons.SocialDistance />,
  Sort: <icons.Sort />,
  SortByAlpha: <icons.SortByAlpha />,
  SoupKitchen: <icons.SoupKitchen />,
  Source: <icons.Source />,
  South: <icons.South />,
  SouthAmerica: <icons.SouthAmerica />,
  SouthEast: <icons.SouthEast />,
  SouthWest: <icons.SouthWest />,
  Spa: <icons.Spa />,
  SpaceBar: <icons.SpaceBar />,
  Speaker: <icons.Speaker />,
  SpeakerGroup: <icons.SpeakerGroup />,
  SpeakerNotes: <icons.SpeakerNotes />,
  SpeakerNotesOff: <icons.SpeakerNotesOff />,
  SpeakerPhone: <icons.SpeakerPhone />,
  Speed: <icons.Speed />,
  Spellcheck: <icons.Spellcheck />,
  Splitscreen: <icons.Splitscreen />,
  Spoke: <icons.Spoke />,
  Sports: <icons.Sports />,
  SportsBar: <icons.SportsBar />,
  SportsBaseball: <icons.SportsBaseball />,
  SportsBasketball: <icons.SportsBasketball />,
  SportsCricket: <icons.SportsCricket />,
  SportsEsports: <icons.SportsEsports />,
  SportsFootball: <icons.SportsFootball />,
  SportsGolf: <icons.SportsGolf />,
  SportsHandball: <icons.SportsHandball />,
  SportsHockey: <icons.SportsHockey />,
  SportsKabaddi: <icons.SportsKabaddi />,
  SportsMartialArts: <icons.SportsMartialArts />,
  SportsMma: <icons.SportsMma />,
  SportsMotorsports: <icons.SportsMotorsports />,
  SportsRugby: <icons.SportsRugby />,
  SportsScore: <icons.SportsScore />,
  SportsSoccer: <icons.SportsSoccer />,
  SportsTennis: <icons.SportsTennis />,
  SportsVolleyball: <icons.SportsVolleyball />,
  Square: <icons.Square />,
  SquareFoot: <icons.SquareFoot />,
  SsidChart: <icons.SsidChart />,
  StackedBarChart: <icons.StackedBarChart />,
  StackedLineChart: <icons.StackedLineChart />,
  Stadium: <icons.Stadium />,
  Stairs: <icons.Stairs />,
  Star: <icons.Star />,
  StarBorder: <icons.StarBorder />,
  StarBorderPurple500: <icons.StarBorderPurple500 />,
  StarHalf: <icons.StarHalf />,
  StarOutline: <icons.StarOutline />,
  StarPurple500: <icons.StarPurple500 />,
  StarRate: <icons.StarRate />,
  Stars: <icons.Stars />,
  Start: <icons.Start />,
  StayCurrentLandscape: <icons.StayCurrentLandscape />,
  StayCurrentPortrait: <icons.StayCurrentPortrait />,
  StayPrimaryLandscape: <icons.StayPrimaryLandscape />,
  StayPrimaryPortrait: <icons.StayPrimaryPortrait />,
  StickyNote2: <icons.StickyNote2 />,
  Stop: <icons.Stop />,
  StopCircle: <icons.StopCircle />,
  StopScreenShare: <icons.StopScreenShare />,
  Storage: <icons.Storage />,
  Store: <icons.Store />,
  StoreMallDirectory: <icons.StoreMallDirectory />,
  Storefront: <icons.Storefront />,
  Storm: <icons.Storm />,
  Straight: <icons.Straight />,
  Straighten: <icons.Straighten />,
  Stream: <icons.Stream />,
  Streetview: <icons.Streetview />,
  StrikethroughS: <icons.StrikethroughS />,
  Stroller: <icons.Stroller />,
  Style: <icons.Style />,
  SubdirectoryArrowLeft: <icons.SubdirectoryArrowLeft />,
  SubdirectoryArrowRight: <icons.SubdirectoryArrowRight />,
  Subject: <icons.Subject />,
  Subscript: <icons.Subscript />,
  Subscriptions: <icons.Subscriptions />,
  Subtitles: <icons.Subtitles />,
  SubtitlesOff: <icons.SubtitlesOff />,
  Subway: <icons.Subway />,
  Summarize: <icons.Summarize />,
  Superscript: <icons.Superscript />,
  SupervisedUserCircle: <icons.SupervisedUserCircle />,
  SupervisorAccount: <icons.SupervisorAccount />,
  Support: <icons.Support />,
  SupportAgent: <icons.SupportAgent />,
  Surfing: <icons.Surfing />,
  SurroundSound: <icons.SurroundSound />,
  SwapCalls: <icons.SwapCalls />,
  SwapHoriz: <icons.SwapHoriz />,
  SwapHorizontalCircle: <icons.SwapHorizontalCircle />,
  SwapVert: <icons.SwapVert />,
  SwapVerticalCircle: <icons.SwapVerticalCircle />,
  Swipe: <icons.Swipe />,
  SwipeDown: <icons.SwipeDown />,
  SwipeDownAlt: <icons.SwipeDownAlt />,
  SwipeLeft: <icons.SwipeLeft />,
  SwipeLeftAlt: <icons.SwipeLeftAlt />,
  SwipeRight: <icons.SwipeRight />,
  SwipeRightAlt: <icons.SwipeRightAlt />,
  SwipeUp: <icons.SwipeUp />,
  SwipeUpAlt: <icons.SwipeUpAlt />,
  SwipeVertical: <icons.SwipeVertical />,
  SwitchAccessShortcut: <icons.SwitchAccessShortcut />,
  SwitchAccessShortcutAdd: <icons.SwitchAccessShortcutAdd />,
  SwitchAccount: <icons.SwitchAccount />,
  SwitchCamera: <icons.SwitchCamera />,
  SwitchLeft: <icons.SwitchLeft />,
  SwitchRight: <icons.SwitchRight />,
  SwitchVideo: <icons.SwitchVideo />,
  Synagogue: <icons.Synagogue />,
  Sync: <icons.Sync />,
  SyncAlt: <icons.SyncAlt />,
  SyncDisabled: <icons.SyncDisabled />,
  SyncLock: <icons.SyncLock />,
  SyncProblem: <icons.SyncProblem />,
  SystemSecurityUpdate: <icons.SystemSecurityUpdate />,
  SystemSecurityUpdateGood: <icons.SystemSecurityUpdateGood />,
  SystemSecurityUpdateWarning: <icons.SystemSecurityUpdateWarning />,
  SystemUpdate: <icons.SystemUpdate />,
  SystemUpdateAlt: <icons.SystemUpdateAlt />,
  Tab: <icons.Tab />,
  TabUnselected: <icons.TabUnselected />,
  TableBar: <icons.TableBar />,
  TableChart: <icons.TableChart />,
  TableRestaurant: <icons.TableRestaurant />,
  TableRows: <icons.TableRows />,
  TableView: <icons.TableView />,
  Tablet: <icons.Tablet />,
  TabletAndroid: <icons.TabletAndroid />,
  TabletMac: <icons.TabletMac />,
  Tag: <icons.Tag />,
  TagFaces: <icons.TagFaces />,
  TakeoutDining: <icons.TakeoutDining />,
  TapAndPlay: <icons.TapAndPlay />,
  Tapas: <icons.Tapas />,
  Task: <icons.Task />,
  TaskAlt: <icons.TaskAlt />,
  TaxiAlert: <icons.TaxiAlert />,
  Telegram: <icons.Telegram />,
  TempleBuddhist: <icons.TempleBuddhist />,
  TempleHindu: <icons.TempleHindu />,
  TenMp: <icons.TenMp />,
  Terminal: <icons.Terminal />,
  Terrain: <icons.Terrain />,
  TextDecrease: <icons.TextDecrease />,
  TextFields: <icons.TextFields />,
  TextFormat: <icons.TextFormat />,
  TextIncrease: <icons.TextIncrease />,
  TextRotateUp: <icons.TextRotateUp />,
  TextRotateVertical: <icons.TextRotateVertical />,
  TextRotationAngledown: <icons.TextRotationAngledown />,
  TextRotationAngleup: <icons.TextRotationAngleup />,
  TextRotationDown: <icons.TextRotationDown />,
  TextRotationNone: <icons.TextRotationNone />,
  TextSnippet: <icons.TextSnippet />,
  Textsms: <icons.Textsms />,
  Texture: <icons.Texture />,
  TheaterComedy: <icons.TheaterComedy />,
  Theaters: <icons.Theaters />,
  Thermostat: <icons.Thermostat />,
  ThermostatAuto: <icons.ThermostatAuto />,
  ThirteenMp: <icons.ThirteenMp />,
  ThirtyFps: <icons.ThirtyFps />,
  ThirtyFpsSelect: <icons.ThirtyFpsSelect />,
  ThreeDRotation: <icons.ThreeDRotation />,
  ThreeGMobiledata: <icons.ThreeGMobiledata />,
  ThreeK: <icons.ThreeK />,
  ThreeKPlus: <icons.ThreeKPlus />,
  ThreeMp: <icons.ThreeMp />,
  ThreeP: <icons.ThreeP />,
  ThreeSixty: <icons.ThreeSixty />,
  ThumbDown: <icons.ThumbDown />,
  ThumbDownAlt: <icons.ThumbDownAlt />,
  ThumbDownOffAlt: <icons.ThumbDownOffAlt />,
  ThumbUp: <icons.ThumbUp />,
  ThumbUpAlt: <icons.ThumbUpAlt />,
  ThumbUpOffAlt: <icons.ThumbUpOffAlt />,
  ThumbsUpDown: <icons.ThumbsUpDown />,
  TimeToLeave: <icons.TimeToLeave />,
  Timelapse: <icons.Timelapse />,
  Timeline: <icons.Timeline />,
  Timer: <icons.Timer />,
  Timer10: <icons.Timer10 />,
  Timer10Select: <icons.Timer10Select />,
  Timer3: <icons.Timer3 />,
  Timer3Select: <icons.Timer3Select />,
  TimerOff: <icons.TimerOff />,
  TimesOneMobiledata: <icons.TimesOneMobiledata />,
  TipsAndUpdates: <icons.TipsAndUpdates />,
  Title: <icons.Title />,
  Toc: <icons.Toc />,
  Today: <icons.Today />,
  ToggleOff: <icons.ToggleOff />,
  ToggleOn: <icons.ToggleOn />,
  Token: <icons.Token />,
  Toll: <icons.Toll />,
  Tonality: <icons.Tonality />,
  Topic: <icons.Topic />,
  TouchApp: <icons.TouchApp />,
  Tour: <icons.Tour />,
  Toys: <icons.Toys />,
  TrackChanges: <icons.TrackChanges />,
  Traffic: <icons.Traffic />,
  Train: <icons.Train />,
  Tram: <icons.Tram />,
  TransferWithinAStation: <icons.TransferWithinAStation />,
  Transform: <icons.Transform />,
  Transgender: <icons.Transgender />,
  TransitEnterexit: <icons.TransitEnterexit />,
  Translate: <icons.Translate />,
  TravelExplore: <icons.TravelExplore />,
  TrendingDown: <icons.TrendingDown />,
  TrendingFlat: <icons.TrendingFlat />,
  TrendingUp: <icons.TrendingUp />,
  TripOrigin: <icons.TripOrigin />,
  Try: <icons.Try />,
  Tty: <icons.Tty />,
  Tune: <icons.Tune />,
  Tungsten: <icons.Tungsten />,
  TurnLeft: <icons.TurnLeft />,
  TurnRight: <icons.TurnRight />,
  TurnSharpLeft: <icons.TurnSharpLeft />,
  TurnSharpRight: <icons.TurnSharpRight />,
  TurnSlightLeft: <icons.TurnSlightLeft />,
  TurnSlightRight: <icons.TurnSlightRight />,
  TurnedIn: <icons.TurnedIn />,
  TurnedInNot: <icons.TurnedInNot />,
  Tv: <icons.Tv />,
  TvOff: <icons.TvOff />,
  TwelveMp: <icons.TwelveMp />,
  TwentyFourMp: <icons.TwentyFourMp />,
  TwentyOneMp: <icons.TwentyOneMp />,
  TwentyThreeMp: <icons.TwentyThreeMp />,
  TwentyTwoMp: <icons.TwentyTwoMp />,
  TwentyZeroMp: <icons.TwentyZeroMp />,
  Twitter: <icons.Twitter />,
  TwoK: <icons.TwoK />,
  TwoKPlus: <icons.TwoKPlus />,
  TwoMp: <icons.TwoMp />,
  TwoWheeler: <icons.TwoWheeler />,
  UTurnLeft: <icons.UTurnLeft />,
  UTurnRight: <icons.UTurnRight />,
  Umbrella: <icons.Umbrella />,
  Unarchive: <icons.Unarchive />,
  Undo: <icons.Undo />,
  UnfoldLess: <icons.UnfoldLess />,
  UnfoldMore: <icons.UnfoldMore />,
  Unpublished: <icons.Unpublished />,
  Unsubscribe: <icons.Unsubscribe />,
  Upcoming: <icons.Upcoming />,
  Update: <icons.Update />,
  UpdateDisabled: <icons.UpdateDisabled />,
  Upgrade: <icons.Upgrade />,
  Upload: <icons.Upload />,
  UploadFile: <icons.UploadFile />,
  Usb: <icons.Usb />,
  UsbOff: <icons.UsbOff />,
  Vaccines: <icons.Vaccines />,
  Verified: <icons.Verified />,
  VerifiedUser: <icons.VerifiedUser />,
  VerticalAlignBottom: <icons.VerticalAlignBottom />,
  VerticalAlignCenter: <icons.VerticalAlignCenter />,
  VerticalAlignTop: <icons.VerticalAlignTop />,
  VerticalSplit: <icons.VerticalSplit />,
  Vibration: <icons.Vibration />,
  VideoCall: <icons.VideoCall />,
  VideoCameraBack: <icons.VideoCameraBack />,
  VideoCameraFront: <icons.VideoCameraFront />,
  VideoFile: <icons.VideoFile />,
  VideoLabel: <icons.VideoLabel />,
  VideoLibrary: <icons.VideoLibrary />,
  VideoSettings: <icons.VideoSettings />,
  VideoStable: <icons.VideoStable />,
  Videocam: <icons.Videocam />,
  VideocamOff: <icons.VideocamOff />,
  VideogameAsset: <icons.VideogameAsset />,
  VideogameAssetOff: <icons.VideogameAssetOff />,
  ViewAgenda: <icons.ViewAgenda />,
  ViewArray: <icons.ViewArray />,
  ViewCarousel: <icons.ViewCarousel />,
  ViewColumn: <icons.ViewColumn />,
  ViewComfy: <icons.ViewComfy />,
  ViewComfyAlt: <icons.ViewComfyAlt />,
  ViewCompact: <icons.ViewCompact />,
  ViewCompactAlt: <icons.ViewCompactAlt />,
  ViewCozy: <icons.ViewCozy />,
  ViewDay: <icons.ViewDay />,
  ViewHeadline: <icons.ViewHeadline />,
  ViewInAr: <icons.ViewInAr />,
  ViewKanban: <icons.ViewKanban />,
  ViewList: <icons.ViewList />,
  ViewModule: <icons.ViewModule />,
  ViewQuilt: <icons.ViewQuilt />,
  ViewSidebar: <icons.ViewSidebar />,
  ViewStream: <icons.ViewStream />,
  ViewTimeline: <icons.ViewTimeline />,
  ViewWeek: <icons.ViewWeek />,
  Vignette: <icons.Vignette />,
  Villa: <icons.Villa />,
  Visibility: <icons.Visibility />,
  VisibilityOff: <icons.VisibilityOff />,
  VoiceChat: <icons.VoiceChat />,
  VoiceOverOff: <icons.VoiceOverOff />,
  Voicemail: <icons.Voicemail />,
  VolumeDown: <icons.VolumeDown />,
  VolumeMute: <icons.VolumeMute />,
  VolumeOff: <icons.VolumeOff />,
  VolumeUp: <icons.VolumeUp />,
  VolunteerActivism: <icons.VolunteerActivism />,
  VpnKey: <icons.VpnKey />,
  VpnKeyOff: <icons.VpnKeyOff />,
  VpnLock: <icons.VpnLock />,
  Vrpano: <icons.Vrpano />,
  Wallpaper: <icons.Wallpaper />,
  Warehouse: <icons.Warehouse />,
  Warning: <icons.Warning />,
  WarningAmber: <icons.WarningAmber />,
  Wash: <icons.Wash />,
  Watch: <icons.Watch />,
  WatchLater: <icons.WatchLater />,
  WatchOff: <icons.WatchOff />,
  Water: <icons.Water />,
  WaterDamage: <icons.WaterDamage />,
  WaterfallChart: <icons.WaterfallChart />,
  Waves: <icons.Waves />,
  WbAuto: <icons.WbAuto />,
  WbCloudy: <icons.WbCloudy />,
  WbIncandescent: <icons.WbIncandescent />,
  WbIridescent: <icons.WbIridescent />,
  WbShade: <icons.WbShade />,
  WbSunny: <icons.WbSunny />,
  WbTwilight: <icons.WbTwilight />,
  Wc: <icons.Wc />,
  Web: <icons.Web />,
  WebAsset: <icons.WebAsset />,
  WebAssetOff: <icons.WebAssetOff />,
  Webhook: <icons.Webhook />,
  Weekend: <icons.Weekend />,
  West: <icons.West />,
  WhatsApp: <icons.WhatsApp />,
  Whatshot: <icons.Whatshot />,
  WheelchairPickup: <icons.WheelchairPickup />,
  WhereToVote: <icons.WhereToVote />,
  Widgets: <icons.Widgets />,
  Wifi: <icons.Wifi />,
  WifiCalling: <icons.WifiCalling />,
  WifiCalling3: <icons.WifiCalling3 />,
  WifiChannel: <icons.WifiChannel />,
  WifiFind: <icons.WifiFind />,
  WifiLock: <icons.WifiLock />,
  WifiOff: <icons.WifiOff />,
  WifiPassword: <icons.WifiPassword />,
  WifiProtectedSetup: <icons.WifiProtectedSetup />,
  WifiTethering: <icons.WifiTethering />,
  WifiTetheringError: <icons.WifiTetheringError />,
  WifiTetheringOff: <icons.WifiTetheringOff />,
  Window: <icons.Window />,
  WineBar: <icons.WineBar />,
  Woman: <icons.Woman />,
  Work: <icons.Work />,
  WorkOff: <icons.WorkOff />,
  WorkOutline: <icons.WorkOutline />,
  WorkspacePremium: <icons.WorkspacePremium />,
  Workspaces: <icons.Workspaces />,
  WrapText: <icons.WrapText />,
  WrongLocation: <icons.WrongLocation />,
  Wysiwyg: <icons.Wysiwyg />,
  Yard: <icons.Yard />,
  YouTube: <icons.YouTube />,
  YoutubeSearchedFor: <icons.YoutubeSearchedFor />,
  ZoomIn: <icons.ZoomIn />,
  ZoomInMap: <icons.ZoomInMap />,
  ZoomOut: <icons.ZoomOut />,
  ZoomOutMap: <icons.ZoomOutMap />,
};
export type FIconItem = keyof typeof IconComponentsByName;
